import React, { useState } from 'react'
import { RichTextContent } from '@/components/design/RichTextContent'
import { imageLoader } from '@/utils/ImageLoaders'
import Image from 'next/image'
export type AccordionPanelProps = {
  headline: string
  richText: any
  isH3?: boolean
  isInitiallyExpanded?: boolean
}

export function AccordionPanel({
  headline,
  richText,
  isH3,
  isInitiallyExpanded = false,
}: AccordionPanelProps): JSX.Element {
  const [showPanelContent, panelContentIsVisible] =
    useState<boolean>(isInitiallyExpanded)

  return (
    <div className="border-b border-darkShell" data-name="accordion-item">
      <button
        className="flex items-baseline w-full pt-6 pb-6 pr-3 lg:pt-8 lg:pr-0 focus:border-blackSand"
        onClick={() => panelContentIsVisible(!showPanelContent)}
        onKeyDown={() => panelContentIsVisible(!showPanelContent)}
        type="button"
        tabIndex={0}
        aria-expanded={showPanelContent}
      >
        {isH3 ? (
          <h3 className="flex-1 pr-10 text-left fora-text-h6 md:fora-text-h7">
            {headline}
          </h3>
        ) : (
          <h2
            className={`flex-1 pr-10 leading-normal text-left fora-text-h6 ${
              showPanelContent ? '' : 'text-darkStone'
            }`}
          >
            {headline}
          </h2>
        )}
        <div className="relative w-24 min-h-full">
          <div className="absolute -top-4 left-20 ">
            <Image
              loader={({ src }) =>
                imageLoader({
                  src: src,
                  width: 14,
                  quality: 90,
                })
              }
              src="https://media.foratravel.com/image/upload/v1728049242/icon-minus-charcoal_k0xfoo.svg"
              alt={`Icon ${showPanelContent ? 'Minus' : 'Plus'}`}
              width={14}
              height={14}
            />
          </div>
          <div className="absolute left-20 -top-4">
            <div
              className={`transition duration-300 transform 
          ${showPanelContent ? 'rotate-0 ' : '-rotate-90'}`}
            >
              <Image
                loader={({ src }) =>
                  imageLoader({
                    src: src,
                    width: 14,
                    quality: 90,
                  })
                }
                src="https://media.foratravel.com/image/upload/v1728049242/icon-minus-charcoal_k0xfoo.svg"
                alt={`Icon ${showPanelContent ? 'Minus' : 'Plus'}`}
                width={14}
                height={14}
              />
            </div>
          </div>
        </div>
      </button>
      <div
        className={`overflow-hidden md:relative md:top-[-15px] transition-all duration-500 ease ${
          showPanelContent ? 'max-h-screen' : 'max-h-0 '
        }`}
      >
        <div className="pb-5 fora-text-body-1">
          <RichTextContent richText={richText} />
        </div>
      </div>
    </div>
  )
}
