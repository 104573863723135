import { AccordionPanel } from '@/components/ui/AccordionPanel'
import {
  FaqsQuestionGroups,
  FaqsQuestions,
  Maybe,
} from 'types/generated/contentful-types'
import { RichTextContent } from '../design/RichTextContent'

type FaqsQuestionGroupsProps = Omit<FaqsQuestionGroups, '_id'> & {
  isH3?: boolean
}

const QuestionGroup = ({
  title,
  richTitle,
  questionsCollection,
  automaticallyExpandQuestions,
  sectionId,
  isH3 = true,
}: FaqsQuestionGroupsProps): JSX.Element => {
  return (
    <section id={sectionId ?? undefined}>
      <div className="pb-4 m-auto lg:pb-6">
        {richTitle?.json ? (
          <h2 className="question-group-richtext">
            <RichTextContent richText={richTitle} />
          </h2>
        ) : (
          <h2 className="fora-text-h3 text-blackSand lg:text-center">
            {title}
          </h2>
        )}
      </div>
      <div className="max-w-3xl m-auto">
        {questionsCollection?.items
          .filter((item) => item)
          .map(
            (question: Maybe<FaqsQuestions>, n: number) =>
              question && (
                <Question
                  key={`Question_${n}`}
                  {...question}
                  automaticallyExpandQuestions={
                    automaticallyExpandQuestions ?? false
                  }
                  isH3={isH3}
                />
              )
          )}
      </div>
    </section>
  )
}

export type QuestionProps = FaqsQuestions & {
  automaticallyExpandQuestions?: boolean
  isH3?: boolean
}

export const Question = ({
  title,
  answer,
  automaticallyExpandQuestions,
  isH3,
}: QuestionProps): JSX.Element => {
  return (
    <AccordionPanel
      isH3={isH3}
      headline={title ?? ''}
      richText={answer}
      isInitiallyExpanded={automaticallyExpandQuestions ?? false}
    />
  )
}

export default QuestionGroup
